/* Fix input text color */
html[data-theme="dark"] input,
html[data-theme="dark"] textarea {
  color: var(--theme-dark);
}

/* Fix label text color */
html[data-theme="dark"] o-field label {
  color: var(--theme-dark);
}

html[data-theme="dark"] o-input-search .o-icon {
  color: var(--theme-dark);
}

/* Fix select text color */
html[data-theme="dark"] o-select button.o-select__header,
html[data-theme="dark"] o-option button.o-option:not(.o-option--selected),
html[data-theme="dark"] o-select-multiple button.o-select__header,
html[data-theme="dark"] o-option-multiple button.o-option-multiple:not(.o-option-multiple--selected) {
  color: var(--theme-light-inverse);
}

/* Fix input border hover */
html[data-theme="dark"] o-input .o-input--flushed:hover:not(:focus),
html[data-theme="dark"] o-input .o-input--outline:hover:not(:focus) {
  border-color: var(--theme-dark-40);
}

/* Fix steps background */
html[data-theme="dark"] o-step .o-step:not(.o-step--status-completed)::before {
  background-color: #777;
}

html[data-theme="dark"] o-step .o-step:not(.o-step--status-completed) .o-step--marker {
  border-color: #777;
  background-color: #777;
}

/* Fix input date text color */
html[data-theme="dark"] .o-input-date-dropdown {
  color: var(--theme-dark);
}

html[data-theme="dark"] .o-input-date-dropdown button {
  color: var(--theme-dark);
}

/* Fix Datagrid row text color */
html[data-theme="dark"] .dx-datagrid {
  color: var(--theme-dark);
}

html[data-theme="dark"] .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-modified):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) .dx-link {
  color: inherit;
}

html[data-theme="dark"] .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
      .dx-row-removed
    ):not(.dx-edit-row):not(.dx-row-focused)
    > td {
      color: #fff;
}

html[data-theme="dark"] .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
    .dx-row-removed
  ):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  background-color: var(--theme-light-hover);
  color: #fff;
}

html[data-theme="dark"] .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
    .dx-row-removed
  ):not(.dx-edit-row):not(.dx-row-focused)
  > .dx-datagrid-readonly
  .dx-texteditor
  .dx-texteditor-input {
  background-color: var(--theme-light-hover);
}

html[data-theme="dark"] .dx-rtl .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: var(--theme-dark-20);
  border-right-color: transparent;
}

html[data-theme="dark"] * {
  --theme-background: #06152e;
  --grid-row-alt: #ffffff0d;

  /* Main Colors */
  --theme-primary: rgb(255 255 255 / 70%);
  --theme-secondary: #d2e5ff;
  --theme-tertiary: #b1d2ff;
  --theme-quaternary: #ffffff;
  --theme-default: rgb(255 255 255 / 60%);
  --theme-success: #62c395;
  --theme-success-light: #106a40;
  --theme-info: #b1d2ff;
  --theme-info-light: #113d88;
  --theme-warning: #ff8752;
  --theme-warning-light: #884426;
  --theme-danger: #f76970;
  --theme-danger-light: #8b2328;
  --theme-light: #092149;
  --theme-dark: #ffffff;
  --theme-current-color: currentColor;

  /* Variation Colors */
  --theme-primary-hover: #363636;
  --theme-primary-active: #101010;
  --theme-secondary-hover: #50565f;
  --theme-secondary-active: #303339;
  --theme-tertiary-hover: #c4ddff;
  --theme-tertiary-active: #87a0c2;
  --theme-quaternary-hover: #1e3966;
  --theme-quaternary-active: #071b3c;
  --theme-default-hover: #7a7a7a;
  --theme-default-active: #585858;
  --theme-success-hover: #1bca76;
  --theme-success-active: #138e53;
  --theme-success-light-hover: #e0efe9;
  --theme-success-light-active: #bcddd0;
  --theme-info-hover: #c4ddff;
  --theme-info-active: #87a0c2;
  --theme-info-light-hover: #d9e9ff;
  --theme-info-light-active: #c4ddff;
  --theme-warning-hover: #ff6e1a;
  --theme-warning-active: #d84f00;
  --theme-warning-light-hover: #ffebd4;
  --theme-warning-light-active: #ffd19e;
  --theme-danger-hover: #ee5a63;
  --theme-danger-active: #e8222e;
  --theme-danger-light-hover: #ffe0e2;
  --theme-danger-light-active: #ffacb0;
  --theme-light-hover: #314566;
  --theme-light-active: #071938;
  --theme-dark-hover: #cccccc;
  --theme-dark-active: #e6e6e6;

  --theme-neutral-light: #1e3966;
  --theme-neutral-normal: #0b2859;
  --theme-neutral-medium: #363636;

  --theme-focus: #a02795;

  /* Opacity Variations */
  --theme-primary-05: rgba(255, 255, 255, 0.05);
  --theme-primary-10: rgba(255, 255, 255, 0.1);
  --theme-primary-15: rgba(255, 255, 255, 0.15);
  --theme-primary-20: rgba(255, 255, 255, 0.2);
  --theme-primary-40: rgba(255, 255, 255, 0.4);
  --theme-primary-60: rgba(255, 255, 255, 0.6);
  --theme-primary-80: rgba(255, 255, 255, 0.8);
  --theme-secondary-05: rgba(210, 229, 255, 0.05);
  --theme-secondary-10: rgba(210, 229, 255, 0.1);
  --theme-secondary-15: rgba(210, 229, 255, 0.15);
  --theme-secondary-20: rgba(210, 229, 255, 0.2);
  --theme-secondary-40: rgba(210, 229, 255, 0.4);
  --theme-secondary-60: rgba(210, 229, 255, 0.6);
  --theme-secondary-80: rgba(210, 229, 255, 0.8);
  --theme-tertiary-05: rgba(177, 210, 255, 0.05);
  --theme-tertiary-10: rgba(177, 210, 255, 0.1);
  --theme-tertiary-15: rgba(177, 210, 255, 0.15);
  --theme-tertiary-20: rgba(177, 210, 255, 0.2);
  --theme-tertiary-40: rgba(177, 210, 255, 0.4);
  --theme-tertiary-60: rgba(177, 210, 255, 0.6);
  --theme-tertiary-80: rgba(177, 210, 255, 0.8);
  --theme-quaternary-05: rgba(11, 40, 89, 0.05);
  --theme-quaternary-10: rgba(11, 40, 89, 0.1);
  --theme-quaternary-15: rgba(11, 40, 89, 0.15);
  --theme-quaternary-20: rgba(11, 40, 89, 0.2);
  --theme-quaternary-40: rgba(11, 40, 89, 0.4);
  --theme-quaternary-60: rgba(11, 40, 89, 0.6);
  --theme-quaternary-80: rgba(11, 40, 89, 0.8);
  --theme-default-05: rgba(98, 98, 98, 0.05);
  --theme-default-10: rgba(98, 98, 98, 0.1);
  --theme-default-15: rgba(98, 98, 98, 0.15);
  --theme-default-20: rgba(98, 98, 98, 0.2);
  --theme-default-40: rgba(98, 98, 98, 0.4);
  --theme-default-60: rgba(98, 98, 98, 0.6);
  --theme-default-80: rgba(98, 98, 98, 0.8);
  --theme-success-05: rgba(98, 195, 149, 0.05);
  --theme-success-10: rgba(98, 195, 149, 0.1);
  --theme-success-15: rgba(98, 195, 149, 0.15);
  --theme-success-20: rgba(98, 195, 149, 0.2);
  --theme-success-40: rgba(98, 195, 149, 0.4);
  --theme-success-60: rgba(98, 195, 149, 0.6);
  --theme-success-80: rgba(98, 195, 149, 0.8);
  --theme-success-light-05: rgba(16, 106, 64, 0.05);
  --theme-success-light-10: rgba(16, 106, 64, 0.1);
  --theme-success-light-15: rgba(16, 106, 64, 0.15);
  --theme-success-light-20: rgba(16, 106, 64, 0.2);
  --theme-success-light-40: rgba(16, 106, 64, 0.4);
  --theme-success-light-60: rgba(16, 106, 64, 0.6);
  --theme-success-light-80: rgba(16, 106, 64, 0.8);
  --theme-info-05: rgba(177, 210, 255, 0.05);
  --theme-info-10: rgba(177, 210, 255, 0.1);
  --theme-info-15: rgba(177, 210, 255, 0.15);
  --theme-info-20: rgba(177, 210, 255, 0.2);
  --theme-info-40: rgba(177, 210, 255, 0.4);
  --theme-info-60: rgba(177, 210, 255, 0.6);
  --theme-info-80: rgba(177, 210, 255, 0.8);
  --theme-info-light-05: rgba(17, 61, 136, 0.05);
  --theme-info-light-10: rgba(17, 61, 136, 0.1);
  --theme-info-light-15: rgba(17, 61, 136, 0.15);
  --theme-info-light-20: rgba(17, 61, 136, 0.2);
  --theme-info-light-40: rgba(17, 61, 136, 0.4);
  --theme-info-light-60: rgba(17, 61, 136, 0.6);
  --theme-info-light-80: rgba(17, 61, 136, 0.8);
  --theme-warning-05: rgba(255, 135, 82, 0.05);
  --theme-warning-10: rgba(255, 135, 82, 0.1);
  --theme-warning-15: rgba(255, 135, 82, 0.15);
  --theme-warning-20: rgba(255, 135, 82, 0.2);
  --theme-warning-40: rgba(255, 135, 82, 0.4);
  --theme-warning-60: rgba(255, 135, 82, 0.6);
  --theme-warning-80: rgba(255, 135, 82, 0.8);
  --theme-warning-light-05: rgba(136, 68, 38, 0.05);
  --theme-warning-light-10: rgba(136, 68, 38, 0.1);
  --theme-warning-light-15: rgba(136, 68, 38, 0.15);
  --theme-warning-light-20: rgba(136, 68, 38, 0.2);
  --theme-warning-light-40: rgba(136, 68, 38, 0.4);
  --theme-warning-light-60: rgba(136, 68, 38, 0.6);
  --theme-warning-light-80: rgba(136, 68, 38, 0.8);
  --theme-danger-05: rgba(247, 105, 112, 0.05);
  --theme-danger-10: rgba(247, 105, 112, 0.1);
  --theme-danger-15: rgba(247, 105, 112, 0.15);
  --theme-danger-20: rgba(247, 105, 112, 0.2);
  --theme-danger-40: rgba(247, 105, 112, 0.4);
  --theme-danger-60: rgba(247, 105, 112, 0.6);
  --theme-danger-80: rgba(247, 105, 112, 0.8);
  --theme-danger-light-05: rgba(139, 35, 40, 0.05);
  --theme-danger-light-10: rgba(139, 35, 40, 0.1);
  --theme-danger-light-15: rgba(139, 35, 40, 0.15);
  --theme-danger-light-20: rgba(139, 35, 40, 0.2);
  --theme-danger-light-40: rgba(139, 35, 40, 0.4);
  --theme-danger-light-60: rgba(139, 35, 40, 0.6);
  --theme-danger-light-80: rgba(139, 35, 40, 0.8);
  --theme-light-05: rgba(0, 0, 0, 0.05);
  --theme-light-10: rgba(0, 0, 0, 0.1);
  --theme-light-15: rgba(0, 0, 0, 0.15);
  --theme-light-20: rgba(0, 0, 0, 0.2);
  --theme-light-40: rgba(0, 0, 0, 0.4);
  --theme-light-60: rgba(0, 0, 0, 0.6);
  --theme-light-80: rgba(0, 0, 0, 0.8);
  --theme-dark-05: rgba(255, 255, 255, 0.05);
  --theme-dark-10: rgba(255, 255, 255, 0.1);
  --theme-dark-15: rgba(255, 255, 255, 0.15);
  --theme-dark-20: rgba(255, 255, 255, 0.2);
  --theme-dark-40: rgba(255, 255, 255, 0.4);
  --theme-dark-60: rgba(255, 255, 255, 0.6);
  --theme-dark-80: rgba(255, 255, 255, 0.8);

  /* Border */
  --border: 1px solid var(--theme-dark-20);
}
