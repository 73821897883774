html {
  font-size: 14px;

  --theme-background: #f2f2f2;
  --grid-row-alt: #59400305
}

o-card {
  border-color: var(--theme-dark-20);
}

/* Fix clickable box text color */
o-clickable-box input:not(:checked)~.o-clickable-box__label {
  background-color: var(--theme-light);
  color: var(--theme-light-inverse);
}

@media (min-width: 767px) {
  html {
    font-size: 16px;
  }
}

html,
body {
  height: 100%;
}

body>#root {
  min-height: 100%;
}

a,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: "Moderat", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-variant: none !important;
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
  margin: 0;
}

/*
 * Fix broken layout right after render
 * by hiding elements before they are hydrated
 */
o-dropdown:not(.hydrated),
o-dropdown-action:not(.hydrated),
o-dropdown-action-item:not(.hydrated),
o-dropdown-item:not(.hydrated),
o-dropdown-list:not(.hydrated),
o-floater:not(.hydrated) {
  display: none;
}


