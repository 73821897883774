.handle {
  width: 14px !important;
  height: 14px !important;
}

.handle-right {
  right: -7px !important;
}

.handle-left {
  left: -7px !important;
}

.badge-style {
  margin-top: 40px !important;
}

.typography-style {
  margin-top: 2px !important;
}

.react-flow__node-PROCESSOR.selected,
.react-flow__node-PROCESSOR:focus,
.react-flow__node-PROCESSOR:focus-visible {
  outline: none;
  border: 1px solid var(--theme-dark);
  box-shadow: 0 0 0 0.5px var(--theme-dark-60);
}

.react-flow__node-PROCESSOR:hover {
  box-shadow: 0 0 0 0.5px var(--theme-dark-60);
  transition: all 0.2s;
}