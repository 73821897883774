.context-menu {
  /* Usar position: absolute  */
  position: absolute !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  box-shadow: 0 0 5px 0 #ccc !important;
  opacity: 1 !important;
  transition: opacity 0.5s linear !important;
  /* usar z-index 4 pois os nodes possuem z-index 3 */
  z-index: 4 !important;
}

.context-menu-item {
  position: relative !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 4px 8px !important;
  cursor: pointer !important;
  width: 100% !important;
  gap: 4px !important;
}

.context-menu-item:hover {
  background-color: #f3f3f3 !important;
}

.context-menu-item:active {
  background-color: #e7e7e7 !important;
}
