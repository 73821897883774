.react-flow__node-GROUP {
  background-color: rgba(240, 240, 240, 0.25);
  border: 1px solid var(--theme-dark-60);
  border-radius: 3px;
}

.react-flow__node-GROUP.selected,
.react-flow__node-GROUP:focus,
.react-flow__node-GROUP:focus-visible {
  outline: none;
  border: 1px solid var(--theme-dark);
  box-shadow: 0 0 0 0.5px var(--theme-dark-60);
}

.react-flow__node-GROUP:hover {
  box-shadow: 0 0 0 0.5px var(--theme-dark-60);
  transition: all 0.2s;
}